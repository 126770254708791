import React, { useState, useEffect,useRef } from "react";
import styles from "../content.module.css";
import Categories from "./Filters";
import jobListInitialData from "assets/jobList";
import companyList from "assets/companyList";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import useAnalytics from "src/assets/useAnalytics"

export default function FiltersList({
  setFilters,
  filter,
  setFilter,
  matchUsers,
  usersList,
}) {
  let list = jobListInitialData.map((item) => item.title);
  const {logEvent,identify} = useAnalytics()

  const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const categories = [
    { name: "Role", menu: list.filter((com) => com.length < 18) },
    { name: "Company", menu: companyList.filter((com) => com.length < 18) },
    { name: "Experience level", menu: ["Entry Level", "Mid Level", "Senior Level"] },
    { name: "Availability", menu: days },
  ];

  const [filterArray, setFilterArray] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [showClearButton, setShowClearButton] = useState(false);
  // Debounce effect for setFilter
  const timerRef = useRef(null);

  // Update searchQuery on input change
  const searchOnChange = (e,clear) => {
    let value = e.target.value;
    if(clear){
      value = ""
    }
    setSearchQuery(value);
  
    if (value.length > 0) {
      setShowClearButton(true);
    } else if (showClearButton) {
      setShowClearButton(false);
    }
  
    // Clear the previous timer
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  
    // Set a new timer
    timerRef.current = setTimeout(() => {
      // Only set filter if searchQuery has changed
      if (!value && showClearButton) {
        setShowClearButton(false);
      }
      if (filter.search !== value) {
        console.log("🚀 ~ timerRef.current=setTimeout ~ value:", value)
        logEvent("search-filter", { searchQuery: value });
        setFilter({ ...filter, search: value });
      }

      if(value.length===0){
        setFilter({ ...filter, search: "" });
      }
    }, 500);
  };
  
  // Clear the timeout on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);
  
  return (
    <div className={styles.filterContainer}>
      {categories.map((category) => (
        <div className={styles.filter} key={category.name}>

        <Categories
        matchUsers={matchUsers}
        category={category.name}
        menu={category.menu}
        setFilter={setFilter}
        filter={filter}
        setFilterArray={setFilterArray}
        filterArray={filterArray}
        />
        </div>
      ))}
      <TextField
      className={styles.searchField}
        onChange={searchOnChange}
        id="outlined-basic"
        label="Search"
        variant="outlined"
        value={searchQuery}
        sx={{
          input: {
            background: "white",
            // width: "100px",
            
          },
          fieldset: { borderColor: "#e5e6e6" },
        }}
        InputProps={{
          style: {
            padding: "0px",
            borderRadius: "10px",
            height: "64px",
          },
          endAdornment: (
            <>
        { showClearButton&&   <ClearIcon onClick={(e) =>{
           searchOnChange(e,true)
           setShowClearButton(false)
          //  matchUsers(true,true)
           }} sx={{cursor:"pointer"}}>
             {/* {true ? <ClearIcon/> : ''} */}
            </ClearIcon>
        }
            </>
          )


        }}
      />
    </div>
  );
}
