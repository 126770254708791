import Banner from "./components/Banner";
import TimeLine from "./components/TimeLine";
import InterviewTypes from "./components/InterviewTypes";
import MeetUsers from "./components/Tools";
import {Helmet} from 'react-helmet'


const Index = () => {
    return (
        <div>
                {/* <title>Peerfect: Practice mock interviews</title> */}
    <Helmet>
    <meta name="description" content="Free online mock interview Practice for Your Dream Job with the perfect peer" />
    <title>Peerfect: Practice mock interviews</title>

  </Helmet>
                  <Banner/>
<InterviewTypes/>
<TimeLine/>
<MeetUsers/>
        </div>
    );
}

export default Index;
