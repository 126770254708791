import React from "react";
import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
//steper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

//file imports

//firebase imports
import { useFireStore } from "hooks/useFireStore";
import { useAuthContext } from "hooks/useAuthContext";
import { useCostumUpdate } from "hooks/useCostumUpdate";
import { Link, useNavigate } from "react-router-dom";

//loading button for submit
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import UserInterviewDetails from "./UserInterviewDetails";
import UserAvailableTime from "./UserAvailableTime";

import interviewquestions from 'assets/questions'
import { useUpdate } from "hooks/useUpdate";
import InterviewTypes from "./InterviewTypes";
import InterviewRole from "./InterviewRole";
import useAnalytics from "src/assets/useAnalytics"
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
const humanizeDuration = require("humanize-duration");

const UserModalContainer = ({
  open,
  time,
  skipNumber,
  handleClose,
 
  item: profile,
}) => {
  console.log("🚀 ~ profile:", profile)
  //data functions
  // console.log(profi)
  const navigate = useNavigate();
  const [chosen, setchosen] = useState(false);
  const {logEvent,identify} = useAnalytics()
  const { documentArrayPush} = useUpdate("privateUsers");

  let modalSteps =  [ 
    //  "Choose Interview Role",
    // 'Choose Interview options',
    "Choose Interview Type", 
  "Choose Interview Time (times are based on Your timezone)"
]
const [loading, setLoading] = useState(null);
const [success, setSuccess] = useState(null);


if ( profile.interviewTypes.length === 0 || profile.interviewTypes[0].toLowerCase() === "general interview") {
  modalSteps = [ "Choose Interview Time (times are based on Your timezone)"];
}

  const { addDocument, repsonse } = useFireStore("requests");
  const { user,dispatch,userData } = useAuthContext();

  const { addNotification } = useCostumUpdate("notification");

  const [requestInfo, setrequestInfo] = useState({
    time: { HourFormated: " initial value", timestamp: 2202156 },
    UserReceivingRequestId: profile.id,
    UserReceivingRequestName: profile.displayName,
    UserReceivingRequestTimeZone: profile.timeZone ||"",
    yoe: profile.yoe || "" , 
    interviewType: profile.interviewTypes.length === 0 ? "general interview" : "",
    interviewDetails: '',
    role: profile.targetRole,
    userSendingRequestId: user ? user.uid : null,
    userSendingRequestTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    userSendingRequestName: user ? user.displayName : null,
    category:profile.category,
    subCategory:profile.subCategory,
    timeChosenYet:true,
    deleted:false
  });

  const submitData = async() => {
console.log(requestInfo)
setLoading(true);

    if (!user) {

      alert("you  must sign up to book a mock interview");
      navigate("./signup");
    }
    let document =requestInfo
    const requestObject = requestInfo

    if(!requestObject.interviewType){
      requestObject.interviewType = "general interview"
    }
    const real =!profile.persona
    console.log("🚀 ~ submitData ~ real:", real,profile.persona)
    // console.log('g',profile)
    if(!real ){
      console.log("not real")
      requestObject.personaId= requestObject.UserReceivingRequestId
      requestObject.UserReceivingRequestId = "jgM0yJ07KWODUOGPGMMLifVHptE2"
      requestObject.UserReceivingRequestTimeZone = 'America/New_York'
    }
    // requests are deleted before 24 hours
    requestObject.deleteTime= requestObject.time.timestamp-86400000
    
    console.log(requestObject) 
    // if()
  //TODO: add company to the object
    addDocument(requestObject);
    // return
    let diffenceTimeStamp= Date.now()-time
    const diffrence = humanizeDuration(diffenceTimeStamp,{ largest: 1,round: true  })
    time= Date.now()
    logEvent('Interview Request',{
      "direct":profile.direct?"direct":"browse",
      "real":profile.real?"real":"persona",
      'duration to choose user':diffrence,
      'duration to pick user in seconds':Math.floor(diffenceTimeStamp/1000),
      'role':requestInfo.role,
      'interview type': requestInfo.interviewType,
      'category': profile.category,
      'subCategory': profile.subCategory,
      'interview time':requestInfo.time.HourFormated,
      'yoe':requestInfo.yoe,
      layout:"list"
    });
    
    //adding notification when a sends a request
    const notificationObject = {
      notificationType: "request",
      userSendingRequest: user ? user.displayName : null,
      userSendingRequestId: user ? user.uid : null,
      chosenTime: requestInfo.time,
      role: requestInfo.role,
      interviewType: requestInfo.interviewType,
      interviewDetails: requestInfo.interviewDetails,
      notificationTimeStamp: Date.now(),
    };
    if(real){
      addNotification(profile.id, notificationObject,'notifications');
    }
   
    console.log(repsonse);
    // documentArrayPush
      // (user.uid,"acceptedUsers",{id:profile.id,dueTime:Date.now()+259200000,swipe:'right'})

      let obj = {
        id:profile.id,
        dueTime:Date.now()+259200000//3days,
      }


      documentArrayPush(user.uid, "acceptedUsers", obj);

    // dispatch({ type: "update", payload: {dataType:'privateData',value:{id:profile.id,dueTime:Date.now()+259200000,swipe:'right'},property:'acceptedUsers'} });
    // handleClose();
    console.log("nooooooooooo")
    setLoading(false)
    setSuccess(true)
    // skips=0
  };

  useEffect(() => {
    console.log(requestInfo)
  }, [requestInfo])
  
  //modal stuff
  const [activeStep, setActiveStep] = useState(0);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleNext = () => {
    console.log(requestInfo,activeStep,"*************")
    let steps ={
      "Choose Interview Type":"request-type",
      1:"request-time"
      // 0:"request-role",
      // 1:"request-type",
      // 2:'request-options',
      // 3:"request-time"
    }
    logEvent(steps[modalSteps[activeStep]],{

    })
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (activeStep === modalSteps.length - 1 ) {
      submitData()
    } else {
      handleNext();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  
  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };

  const props = {
    userData,
    user,
    setchosen,
    profile,
    requestInfo,
    setrequestInfo,
  };


  const close=()=>{
    if(success){
         dispatch({ type: "update", payload: {dataType:'privateData',value:{id:profile.id,dueTime:Date.now()+259200000,swipe:'right'},property:'acceptedUsers'} });
    }
    handleClose()
  }
  return (
    <div>
      <BootstrapDialog
        maxWidth={activeStep === 2 ? "md" : "sm"}
        fullScreen={fullScreen}
        onClose={close}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
      >
        <form onSubmit={handleSubmit}>
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={close}
          >
           {!success? 
           <Stepper activeStep={activeStep} alternativeLabel>
              {modalSteps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                //
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            :<div className="">
            <div className="">
                <h1 className="text-2xl font-semibold text-center mb-4">Interview Request Sent</h1>
                <p className="text-gray-700 text-center mb-4">
                    We have sent a booking request to your peer. If they accept, you will receive a confirmation shortly.
                </p>
                <p className="text-gray-700 text-center mb-6 text-base">
                    While you wait, we encourage you to send more requests! The more requests you send, the more likely you are to find a partner.
                </p>
         
            </div>
        </div>
        }
          </BootstrapDialogTitle>

          {/* modal main stuff */}
          {!success &&<>
            <DialogContent dividers sx={{m:0,p:0,bgcolor:activeStep === 3?'#f5f5f5':'white'}}>
       
        {/* modalSteps[activeStep] ==="" */}

            {/* {activeStep === 0 && <InterviewRole {...props}/>}
            {activeStep === 2 && <UserInterviewDetails {...props}/>} */}
            {modalSteps[activeStep] ==="Choose Interview Type"  && <InterviewTypes {...props}/>}
            {modalSteps[activeStep] ==="Choose Interview Time (times are based on Your timezone)"  && <UserAvailableTime {...props}/>}
    
            
          
          </DialogContent>

          {/* modal footer */}
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-between",
              p: 1,
              m: 1,
              bgcolor: "background.paper",
              borderRadius: 1,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            
            {activeStep === modalSteps.length - 1 ? (
              //  <Button onClick={handleSubmit}>  Submit </Button>
              <Box sx={{ m: 1, position: "relative" }}>
                {!success && (
                  <Button
                    variant="contained"
                    sx={buttonSx}
                    type="submit"
                    disabled={loading || requestInfo.timeChosenYet}
                  >
                    Send Request
                  </Button>
                )}
                {success && (
                  <Button
                    variant="contained"
                    sx={{ bgcolor: "#4caf50" }}
                    disabled={loading}
                    type="submit"
                  >
                    Success
                  </Button>
                )}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
              </Box>
            ) : (
              <Button type="submit" disabled={(requestInfo.interviewType ==="" &&activeStep===0 )}> Next</Button>
            )}
          </DialogActions>
          </>}
        
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default UserModalContainer;
