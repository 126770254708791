import React from "react";
import styles from "./content.module.css";

import UsersList from "./tinderLayout/UsersList";
import { useState, useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import useCollection from "../../hooks/useCollection";
import { useDocumentList } from "../../hooks/useDocumentList";
import MiniSearch from "minisearch";
import getDocument from "hooks/useDoc";

import { useUpdate } from "../../hooks/useUpdate";

import FiltersList from "./components/FiltersList";
import { Typography } from "@mui/material";
import fantoms from "assets/fantoms";
import UserListCardsLayout from "./userlist/Index";
import CardSkeleton from "./userlist/CardSkeleton";
import { doc } from "firebase/firestore";

import { usePostHog } from "posthog-js/react";

import useRankUsers from "./useRankUsers";

export default function Body() {
  const posthog = usePostHog();

  const { user, userData, privateData } = useAuthContext();
  const { getUsers } = useDocumentList();
  const [rolesList, setRolesList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [ready, setReady] = useState(false);
  const [firstTime, setFirstTime] = useState(true);
  const [documentsList, setDocumentList] = useState(null);
  const [filter, setFilter] = useState({
    role: null,
    Type: null,
    Company: null,
    topic: null,
    Role: null,
    day: null,
  });

  const rankUsers = useRankUsers(
    documentsList,
    setReady,
    setUsersList,
    filter,
    posthog,
    privateData,
    userData,
    user
  );

  const matchUsers = async () => {
    if (!documentsList) return;
    setReady(false);
    setUsersList([]);

    let { delay, rankedUsers } = rankUsers();
    console.log("🚀 ~ matchUsers ~ rankedUsers:", rankedUsers);
    if (delay || !firstTime) {
      //add artificial delay
      await new Promise((resolve) => setTimeout(resolve, 500));
    }

    if (rankedUsers.length === 0 && delay) {
      setReady("no results");
    } else if (rankedUsers.length === 0 && !delay) {
      setReady("runout");
      // update(user.uid,{ignoredUsers:[]})
    } else {
      setReady("some results");
    }
    if (false) {
      // if (import.meta.env.DEV) {
      setUsersList(
        rankedUsers
          .sort((a, b) => a.activeDuration - b.activeDuration)
          .reverse()
      );
    } else {
      if (firstTime) setFirstTime(false);
      setUsersList([...rankedUsers]);
    }
  };

  useEffect(() => {
    if (privateData && userData && documentsList) {
      matchUsers();
    }
  }, [userData, filter, privateData, documentsList]);

  useEffect(() => {
    (async () => {
      let docId = "6YgYuXNdYuagxtlCsPMH";

      let [documents, global] = await Promise.all([
        getUsers(),
        getDocument("global", docId),
      ]);
      console.log("🚀 ~ documents:", documents);

      if (global) {
        setRolesList(global.roles);
        setDocumentList(documents);
      } else {
        setDocumentList(documents);
      }
    })();
  }, []);

  return (
    <>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", margin: 3 }}
      >
        Find Mock Interview Partner
      </Typography>
      {/* <div className={`${posthog.getFeatureFlag('usersLayout') === 'tinderLayout'&&styles.tinderLayout} ${styles.container}`}> */}
      <div className={styles.container}>
        <FiltersList
          setFilter={setFilter}
          filter={filter}
          matchUsers={matchUsers}
          usersList={usersList}
          rolesList={rolesList}
        />

        {/* //this was for rendering tinder layout */}
        {/* {posthog.getFeatureFlag('usersLayout') === 'tinderLayout'?  <div className={styles.users}> */}
        {/* <div className={styles.users}>
          <UsersList documents={usersList} ready={ready} />
        </div>
         */}
        <div className={styles.users}>
          {ready ? (
            <>
              <UserListCardsLayout
                documents={usersList}
                ready={ready}
                documentsList={documentsList}
              />
            </>
          ) : (
            <>
              <CardSkeleton />
              <CardSkeleton />
              <CardSkeleton />
            </>
          )}
        </div>
      </div>
    </>
  );
}
