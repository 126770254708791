import MiniSearch from "minisearch";
import levenshtein from "js-levenshtein";

const useRankUsers = (documentsList,setReady,setUsersList,filter,posthog,privateData,userData,user) => {
console.log("🚀 ~ useRankUsers ~ userData:", )



function filterUsers(filter, rankedUsers, searchFilter) {
    let delay = false;
    if (filter["Role"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) => item.targetRole === filter["Role"] ||
          item.currentPosition === filter["Role"]
      );
    }
    if (filter["Company"]) {
      delay = true;
      rankedUsers = rankedUsers.filter(
        (item) => item.targetCompany === filter["Company"] ||
          item.currentCompany === filter["Company"]
      );
    }
    if (filter["Experience level"]) {
      delay = true;
      if (filter["Experience level"] === "Entry Level") {
        rankedUsers = rankedUsers.filter((item) => item.yoe <= 2);
      } else if (filter["Experience level"] === "Mid Level") {
        rankedUsers = rankedUsers.filter(
          (item) => item.yoe >= 2 && item.yoe <= 5
        );
      } else if (filter["Experience level"] === "Senior Level") {
        rankedUsers = rankedUsers.filter((item) => item.yoe >= 5);
      }
    }
    if (filter["Availability"]) {
      delay = true;
  
      //TODO:update all availability to availability
      rankedUsers = rankedUsers.filter((item) => {
        if (Array.isArray(item.availability)) {
          return item.availability.find(
            (time) => time.hour.length > 0 && time.day === filter["Availability"]
          );
        }
        return false;
      });
    }
    if (searchFilter) {
      delay = true;
    }
    return { delay, rankedUsers };
  }



  const search=(searchFilter,filteredArray)=>{

    let searchQuery = `real peerfect2023 ${userData.targetRole
        .toLowerCase()
        .replace(/-/g, "")} ${userData.targetCompany} ${userData.category} ${
        userData.subCategory
      }`;
      if (searchFilter) {
        searchQuery = searchFilter;
      }
    let miniSearch = new MiniSearch({
        fields: searchFilter
          ? ["targetRole", "currentPosition", "targetCompany", "currentCompany"]
          : [
              "platform",
              // "platform",
              "targetRole",
              // "targetCompany",
              // "category",
              // "experience",
              // "university",
              // "areaOfStudy",
              // "currentPosition",
              // "currentCompany",
              // "educationLevel",
              // "bootCamp",
              // "real",
              "category",
              "subCategory",
              "real",
            ], // fields to index for full-text search
        storeFields: Object.keys(userData), // fields to return with search results
        // storeFields: Object.keys(documentsList[0])
      });

    miniSearch.addAll(filteredArray);
    return miniSearch.search(searchQuery, {
        fuzzy: 0.2,
        boost: {
          subCategory: 50,
          category: 100,
          targetRole: 400,
          targetCompany: 50,
          areaOfStudy: 30,
        },
        prefix: true,
      });
  }


  function hoursScore(numberOfHours) {
    const hoursInADay = 24;
    const hoursInAMonth = 30 * hoursInADay; // 30 days in a month
  
    if (numberOfHours < hoursInADay) {
      return 1000; // If signed up in the last 24 hours, score is 1000
    }
  
    if (numberOfHours >= hoursInAMonth) {
      return 0; // If it's been more than a month, score is 0
    }
  
    // Spread the score decrease gradually from 1000 to 0 over the course of the month
    const baseScore = 1000;
    const scoreDecay =
      (numberOfHours - hoursInADay) / (hoursInAMonth - hoursInADay); // Proportion of the time passed after 24 hours
    let score = baseScore * (1 - scoreDecay);
  
    return Math.max(Math.floor(score), 0); // Return a score between 1000 and 0
  }

  const matchUsersNormal=(users)=>{

  // targetRole
  //creation date/ last time active, activeDuration,
  // yoe
  //category
  //sub category
  // timeZone
// TODO: use last active time to calculate score instead of account creation date
  return users
  .map((user) => {
    let score = 0;
    let createdAt= user.activeDuration - 604800000
    let hoursSinceSignedUp = Math.floor(
      (Date.now() - createdAt) / 1000 / 60 / 60
    );
    if(levenshtein(user.targetRole, userData.targetRole) <= 5){
        score += 4500
    }
    if(user.subCategory === userData.subCategory){
        score += 4000
    }
    if(user.category === userData.category){
        score += 1000
    }
    if(levenshtein(user.currentPosition, userData.currentPosition) <= 5){
        score += 1000
    }

    // score -= hoursSinceSignedUp*2
    score += hoursScore(hoursSinceSignedUp)*10;
    // score += user.interviews*200

    user.topics.forEach(topics => {

        if(userData.topics.includes(topics)){
            score += 500
        }
    });

    if(user.requests===0){
      score+=3000
    }

    if(user.requests >3){
      score-= user.requests*1000
    }
    // user.score = score

    return {
        ...user,
      score,
    };
  })
  .sort((a, b) => b.score - a.score);
  }
  const rankUsers =  () => {

    let searchFilter =  filter["search"];
 

    let list = documentsList.map((item) => {
      if (!item.persona) {
        item.real = "real";
      }
      return item;
    });
    let total = [];
    // let total = [...acceptedUsers, ...ignoredUsersUpdated];
    // eslint-disable-next-line no-constant-condition
    if(posthog.getFeatureFlag('usersLayout') === 'tinderLayout'){
      const { acceptedUsers, ignoredUsers } = privateData;
      let ignoredUsersUpdated =
        ignoredUsers.filter((item) => item.dueTime >= Date.now()) || [];
  
       total = [...acceptedUsers, ...ignoredUsersUpdated];
  
       list = documentsList.map((item) => {
        if (!item.persona) {
          item.real = "real";
        }
        return item;
      });
    }
 
    let filteredArray = [...list]
      .filter((item) => !total.some((obj) => obj.id === item.id))
      .filter((item) => item.id != user.uid)
      .filter((item) => !item.error)
      .map((item) => {
        item.targetRole = item.targetRole.toLowerCase().replace(/-/g, "");
        return item;
      });

   
    let rankedUsers;

    rankedUsers = matchUsersNormal(filteredArray)
    if(searchFilter){
        rankedUsers = search(searchFilter,filteredArray)
    }

     let { delay, rankedUsers:rankedUsersByFilters } = filterUsers(filter, rankedUsers, searchFilter)
     console.log("🚀 ~ rankUsers ~ rankedUsers:", rankedUsersByFilters)
     return {delay:delay||searchFilter,rankedUsers:rankedUsersByFilters}
  };

return rankUsers
}

  export default useRankUsers