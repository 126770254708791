import {React,useEffect,useState} from 'react'
import "./Request.css"
import { useFireStore } from 'hooks/useFireStore'
import RequestTable from "./RequestTable"
import {useCostumUpdate} from "hooks/useCostumUpdate"
import RequestList from "./RequestList"
import useAnalytics from "src/assets/useAnalytics"
import RequestCard from "./RequestCard"
import {isMobile} from 'react-device-detect';

const Request = ({documents,user}) => {
  console.log("🚀 ~ RequestList ~ documents:", documents)
  const humanizeDuration = require("humanize-duration");
  const {logEvent,identify} = useAnalytics()

  const {addDocument, repsonse} = useFireStore('interviews')
  const {deleteDocument} = useFireStore('requests')
  const {addNotification} = useCostumUpdate("notification")
  const [loading, setLoading] = useState(null);
  const [success, setSuccess] = useState(false);


  const accept =async(index)=>{
    if(loading){
       return
    }

    
    // setSuccess(false);
     setLoading(index);
    const formatedDate = new Date(documents[index].time.timestamp).toDateString() 
    const formatedTime = new Date(documents[index].time.timestamp).toLocaleTimeString("en-US")
    const time = formatedDate.substring(10,0) +' at ' +formatedTime.replace(':00 ',' ')

      
    let requestDoc = documents[index]
      console.log("🚀 ~ accept ~ requestDoc:", requestDoc)
    
      const post = {
        userIds:[requestDoc.UserReceivingRequestId,requestDoc.userSendingRequestId],
        userNames:[requestDoc.UserReceivingRequestName,requestDoc.userSendingRequestName],
        users:[ {
          id:requestDoc.UserReceivingRequestId,questions:[],name:requestDoc.UserReceivingRequestName,time},
        {id:requestDoc.userSendingRequestId,questions:[],name:requestDoc.userSendingRequestName,time:requestDoc.times[0]}],
        date:[time,requestDoc.times[0]],
        yoe:requestDoc.yoe,
        interviewDetails:requestDoc.interviewDetails,
        interviewType:requestDoc.interviewType,
        role:requestDoc.role,
        time:requestDoc.time,        
        dayRemainder:(requestDoc.time.timestamp-((3600*24-120)*1000)),
        dayRemainderStatus:"scheduled",
        hourRemainder:(requestDoc.time.timestamp-4200*1000),
        hourRemainderStatus:"scheduled",
        interviewRemainder:(requestDoc.time.timestamp-120*1000),
        interviewRemainderStatus:"scheduled",
        reviewTime:(requestDoc.time.timestamp+5400*1000),
        reviewTimeStatus:"scheduled",
        questionsNeeded : true,
        topic:requestDoc.topic  ||"",
        personaId:requestDoc.personaId || "",
        'category': "post.category",
        'subCategory': "post.subCategory",
        deleted:false,
      }


     await addDocument(post)
     
    //!  TODO:production: disable
     if(import.meta.env.MODE === 'development') {
       return
      }
      logEvent('Interview Accept',{
        "persona":post.personaId?"persona":"real",
        'role':post.role,
        'yoe': post.yoe,
        'interview type': post.interviewType,
        'Time from Acceptance to Interview in days':Math.floor((post.time.timestamp-Date.now())/1000/60/60/24),
        'Time from Acceptance to Interview in hours':Math.floor((post.time.timestamp-Date.now())/1000/60/60),
        'Time from Acceptance to Interview':humanizeDuration((post.time.timestamp-Date.now()),{ largest: 1,round: true  }),
        'category': post.category,
        'subCategory': post.subCategory,
        'interview time':post.time.HourFormated,
      });
      // Uncommented code here
    
    await deleteDocument(requestDoc.id)
    setSuccess(true);
    setLoading(null);

 
    //  console.log('working not',result)
      const notificationObject= {
        chosenTime:requestDoc.time,
        notificationType:"Accept",
        role:requestDoc.role,
        user:requestDoc.UserReceivingRequestName,
        interviewType:requestDoc.interviewType,
        notificationTimeStamp: Date.now(),
      }
  
      addNotification(requestDoc.userSendingRequestId,notificationObject,'notifications')


  }
  
    useEffect(() => {
      console.log(repsonse)
      
    }, [repsonse])

   const handleDelete = (index) =>{
    logEvent('request-delete',{
      'yoe': documents[index].yoe,
      'Interview type': documents[index].interviewType,
      'role':documents[index].role,
      'Time to Interview in seconds':Math.floor((documents[index].time.timestamp-Date.now())/1000),
      'Time to Interview':humanizeDuration((documents[index].time.timestamp-Date.now()),{ largest: 1,round: true  })
    });
    deleteDocument(documents[index].id)

   }
 
   useEffect(() => {
    if(documents?.length>0){
      logEvent('request_viewed');

    }
   },[])
  return (
    <div>
          
           {/* <RequestComponent requests={requests} />  */}
        {!isMobile &&  <RequestTable documents={documents} handleDelete={handleDelete} accept={accept} success={success} loading={loading} />}
      { isMobile && <RequestList documents={documents} handleDelete={handleDelete} accept={accept} success={success} loading={loading}/>}
    </div>
  );
}

export default Request;
